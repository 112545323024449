import { TextComponentProps } from '~/ui/components/text';

import { TableCellText } from './TableCellText';

export function TableEmptyCellText({ children, ...rest }: TextComponentProps) {
  return (
    <TableCellText theme="quaternary" {...rest}>
      {children ?? 'None'}
    </TableCellText>
  );
}
