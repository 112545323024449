import { mergeClasses, theme } from '@expo/styleguide';
import { motion, AnimatePresence } from 'framer-motion';
import { type PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  loading?: boolean;
  height?: string | number;
  width?: string | number;
  className?: string;
  containerClassName?: string;
  wrapperClassName?: string;
}>;

export function ContentLoading({
  children,
  loading = false,
  height = '1rem',
  width = '6rem',
  className,
  containerClassName,
  wrapperClassName,
}: Props) {
  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    transition: { duration: 0.15 },
  };

  return (
    <div
      className={mergeClasses('relative flex items-center', containerClassName)}
      style={
        loading
          ? {
              minHeight: height,
              minWidth: width,
            }
          : {}
      }>
      <AnimatePresence initial={false}>
        {loading && (
          <motion.div
            {...motionProps}
            style={{
              height,
              width,
              backgroundColor: theme.background.element,
            }}
            className={mergeClasses(
              'absolute rounded-lg bg-element bg-gradient-to-l from-element via-subtle to-element bg-no-repeat',
              'animate-contentLoading transition duration-[5s]',
              className
            )}
          />
        )}
      </AnimatePresence>
      <AnimatePresence initial={false}>
        {!loading && (
          <motion.div {...motionProps} className={mergeClasses('contents', wrapperClassName)}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
