import { createContext, PropsWithChildren } from 'react';

export type HeaderTheme = 'primary' | 'secondary';

type HeaderProps = PropsWithChildren<{ theme?: HeaderTheme }>;

export const HeaderContext = createContext<{ theme?: HeaderTheme }>({});

export function TableHeader({ theme, children }: HeaderProps) {
  return <HeaderContext.Provider value={{ theme }}>{children}</HeaderContext.Provider>;
}
