import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

import { FOOTNOTE } from '~/ui/components/text';

type HeaderCellTextProps = HTMLAttributes<HTMLDivElement>;

export function TableHeaderCellText({ children, className, ...rest }: HeaderCellTextProps) {
  return (
    <FOOTNOTE
      {...rest}
      theme="secondary"
      className={mergeClasses('inline-block truncate whitespace-nowrap !font-medium', className)}>
      {children}
    </FOOTNOTE>
  );
}
