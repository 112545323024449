import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

type SeparatorProps = HTMLAttributes<HTMLDivElement>;

export function TableSeparator({ className, ...rest }: SeparatorProps) {
  return (
    <div
      {...rest}
      className={mergeClasses('col-span-full h-0 w-full border-t border-secondary', className)}
    />
  );
}
