import { mergeClasses } from '@expo/styleguide';
import { HTMLAttributes } from 'react';

export type CellProps = HTMLAttributes<HTMLDivElement> & {
  theme?: 'default' | 'interactive';
  hideOnMobile?: boolean;
  mobilePrimaryCell?: boolean;
  isActivityView?: boolean;
};

export function TableCell({
  children,
  theme = 'default',
  className,
  mobilePrimaryCell,
  hideOnMobile,
  isActivityView,
  ...rest
}: CellProps) {
  return (
    <div
      {...rest}
      className={mergeClasses(
        hideOnMobile ? 'flex max-md-gutters:hidden' : 'flex',
        theme === 'default' && 'px-4 py-2',
        theme === 'interactive' && 'm-1 truncate rounded-md px-3 py-1 hocus:bg-element',
        mobilePrimaryCell &&
          theme === 'interactive' &&
          'm-1 rounded-md px-3 py-1 max-md-gutters:m-0 max-md-gutters:py-3',
        mobilePrimaryCell &&
          theme === 'interactive' &&
          !isActivityView &&
          'max-md-gutters:rounded-none',
        mobilePrimaryCell && 'max-md-gutters:w-full',
        isActivityView && 'max-md-gutters:rounded-b-md max-md-gutters:rounded-t-none',
        className
      )}>
      {children}
    </div>
  );
}
