import { CALLOUT, TextComponentProps } from '~/ui/components/text';

export function TableCellText({
  children,
  className,
  theme = 'default',
  ...rest
}: TextComponentProps) {
  return (
    <CALLOUT {...rest} theme={theme} className={className}>
      {children}
    </CALLOUT>
  );
}
